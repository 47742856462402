<template>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <BreadCrumb :segments="segments" :page-name="pageName"></BreadCrumb>
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-5 d-print-none">
                        <div class="card card-secondary">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group cart-tool-select2">
                                            <label>Add Product</label>
                                            <vSelect @search="searchProduct" :filterable="false" :clearable="false" :options="searchedProducts" v-on:option:selected ="addProduct">
                                                <template v-slot:option="option">
                                                    {{ option.name }}
                                                    <div class="text-sm font-italic">{{ option.category_name }}</div>
                                                </template>
                                                <template v-slot:no-options="{ search, searching }">
                                                    <template v-if="searching">
                                                        No results found for <em>{{ search }}</em>.
                                                    </template>
                                                    <em style="opacity: 0.5;" v-else>Start typing to search.</em>
                                                </template>
                                            </vSelect>
                                        </div>
                                        <ul class="list-group">
                                            <li v-for="product in printProducts" :key="product.id" class="list-group-item product_row">{{product.name}} <button type="button" class="btn btn-xs btn-default text-danger float-right" v-on:click="removeProduct(product.id)"><i class="far fa-times-circle"></i></button></li>
                                        </ul>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Include Name?</label>
                                            <select class="form-control" v-model="include_name">
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <label>Include Price?</label>
                                            <select class="form-control" v-model="include_price">
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <label>Include Code?</label>
                                            <select class="form-control" v-model="include_code">
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <label>Number of Labels</label>
                                            <input type="number" step="1" v-model.number="number_of_labels" class="form-control">
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6" id="status"></div>
                                            <div class="col-sm-6">
                                                <button type="submit" class="btn btn-success btn-sm float-right">Generate <i class="fas fa-chevron-circle-right"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-7" id="label-preview-container">
                        <div class="card card-default" id="label-preview">
                            <div class="card-header d-print-none">
                                <h3 class="card-title">Preview</h3>
                                <div class="card-tools">
                                    <button type="button" class="btn btn-secondary btn-sm" v-on:click="printLabels" v-if="printProducts.length > 0"><i class="fas fa-print"></i> Print</button>
                                </div>
                            </div>
                            <div class="card-body">
                                <div id="label-print-container" v-if="number_of_labels > 0">
                                    <div v-for="product in printProducts" :key="product.id">
                                        <div v-for="index in number_of_labels" :key="index" class="print-label">
                                            <div v-if="include_name === 'yes'">{{product.name}}</div>
                                            <div v-if="include_price === 'yes'">{{getCurrencyFormattedVal(product.sell_rate)}}</div>
                                            <div><img :src="$apiBaseURL + '/' + product.barcode" class="img-fluid" /></div>
                                            <div v-if="include_code === 'yes'">{{product.code}}</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div><!--/. container-fluid -->
        </section>
        <!-- /.content -->
    </div>
</template>

<script>
    import BreadCrumb from "../../../components/app/common/BreadCrumb";
    import Vue from 'vue'
    import vSelect from 'vue-select'
    import 'vue-select/dist/vue-select.css';
    import {getCurrencyFormattedVal} from "@/helpers/currencyHelper";
    import {ProductService} from "@/services/product-service";
    let productService = new ProductService();
    export default {
        name: 'ProductLabels_view',
        data: function(){
            return {
                pageName: 'Product Labels',
                segments: [
                    { link: true, text: 'Products', routeName: 'App.Products' },
                    { link: false, text: 'Labels' },
                ],
                include_name: 'yes',
                include_price: 'yes',
                include_code: 'yes',
                number_of_labels: 8,
                printProducts: [],
                searchedProducts: [],
            }
        },
        props: {},
        components: {
            BreadCrumb, vSelect
        },
        computed: {

        },
        methods:{
            getCurrencyFormattedVal,
            searchProduct: async function (search, loading) {
                if (search.length) {
                    loading(true);
                    let response = await productService.searchProduct({
                        keyword: search,
                        limit: 5
                    });
                    if (response.isSuccess) {
                        this.searchedProducts = response.products;
                    }else{
                        Vue.$toast.open({message: response.message, type: 'error'});
                    }
                    loading(false);
                }
            },
            addProduct: function (product) {
                if(this.printProducts.some( (x) => x.id === product.id )){
                    Vue.$toast.open({ message: product.name + ' is already selected',  type: 'error'});
                }else{
                    this.printProducts.push(product);
                }

            },
            removeProduct: function (id) {
                if(confirm("Are you sure?")) {
                    this.printProducts = this.printProducts.filter(obj => obj.id !== id);
                }
            },
            printLabels: function () {
                window.print();
            }
        },
        created() {
            if(this.$route.params.product){
                this.addProduct(this.$route.params.product);
            }
        }
    }
</script>

<style scoped>

</style>
