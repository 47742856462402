import { render, staticRenderFns } from "./ProductLabels_view.vue?vue&type=template&id=f983f446&scoped=true&"
import script from "./ProductLabels_view.vue?vue&type=script&lang=js&"
export * from "./ProductLabels_view.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f983f446",
  null
  
)

export default component.exports